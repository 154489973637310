import React from "react";
import NavBar from "../components/NavBar/NavBar";
import "./bikePledge.scss"
import { StaticImage } from "gatsby-plugin-image";


const bikePledge = () => {
return (<>
<NavBar/>
<div className = "pledgeWrapper">
    <div className = "bikePledge">
        <h1 className = "pledge">Pledge for Safer Bicycle Infrastructure</h1>
        <div className = "pledgeBody">
            <p>I support rapid implementation of the <b>citywide network of protected bicycle lanes</b> as mandated by the Cycling Safety Ordinance. </p>
            <p><b>I pledge</b> to do everything in my power to ensure the successful implementation of the ordinance, including voting in the City Council, advocating in the public realm, and connecting stakeholders to ensure a positive outcome for all in our community. I will not vote for any proposal that weakens the ordinance or delays its timelines. </p>
            <p>Mass Ave is the most important street in Cambridge and needs protected bike lanes as soon as possible. I will not vote for any proposal that delays protected bike lanes on Mass Ave beyond the timeline of the rest of the ordinance. This should be done in a way that <b>supports transit, improves pedestrian safety, protects the tree canopy, and enhances the business environment.</b></p>
        </div>
        <StaticImage src = "../images/dana_sig.png" placeholder="blurred" className="signature"/>
    </div>
</div>
</>)


}
export default bikePledge